import React, { useContext, useEffect, useState } from 'react'
import Layout from '../components/Layout'
import axios from 'axios'
import { UserContext } from '../context'
import LoginForm from '../components/loginForm'
import CartRow from '../components/CartList/CartRow'

function Carts() {
    const [carts, setCarts] = useState([])
    const [loading, setLoading] = useState(true)
    const { user } = useContext(UserContext)

    useEffect(() => {
        // axios
        axios.get("/api/carts", {
            headers: {
                "x-auth-token": process.env.OMS_XAUTHTOKEN,
                "api-key": process.env.OMS_APIKEY,
                "secret-key": process.env.OMS_SECRETKEY
            }
        })
            .then(res => {
                setCarts(res.data.carts)
            }).catch(err => {
                console.log("Error: ", err)
            })
        setLoading(false)
    }, [])

    return (
        <Layout>
            {!user.jwt ?
                <LoginForm />
                :
                loading || carts.length < 1 ? <span>Loading...</span>
                    :
                    <table>
                        <thead className='bg-slate-300'>
                            <tr className='text-left'>
                                <th>ID</th>
                                <th>Customer Email</th>
                                <th>Customer Number</th>
                                <th>Items</th>
                                <th>Price</th>
                                <th>Reason</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {carts.map((cart, i) => (<CartRow key={i} i={i} cart={cart} />))}
                        </tbody>
                    </table>
            }
        </Layout>
    )
}

export default Carts