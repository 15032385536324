import React, { useContext, useState } from 'react'
import logoW from "../assets/images/logo-w.png"
import { Link } from "react-router-dom";
import { UserContext } from '../context';
import { setUser } from '../context/actions';
import { MdMenu } from 'react-icons/md'
import SideBar from './SideBar';

export default function Header() {
  const { user, dispatchUser, defaultUser } = useContext(UserContext)
  const [showMenu, setShowMenu] = useState(false)

  const handleLogout = () => (dispatchUser(setUser(defaultUser)))

  return (
    <div className="relative">
      <header className='border-b text-white flex gap-6 border-black h-12 w-full bg-primary items-center'>
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="h-full border-r text-white border-white py-1 px-2 hover:bg-primary-dark"
            >
              <MdMenu className='text-3xl'/>
            </button>
            <Link to="/" className='flex gap-4 items-center'>
              <img src={logoW} className="h-5 -mt-1" />
              <span className=' font-semibold'>OPERATIONS PORTAL</span>
            </Link>
      </header>
      <SideBar showMenu={showMenu}/>
    </div>
  )
}
