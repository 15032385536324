import { SET_USER } from "../actions/action-types";

export default function userReducer(state, action) {
    const { user } = action.payload
    let newState = { ...state }
    const ISSERVER = typeof window === "undefined";

    switch (action.type) {
        case SET_USER:
            if (user.username === "Guest" && !ISSERVER) {
                localStorage.removeItem("user")
            } else {
                if(!ISSERVER){ localStorage.setItem("user", JSON.stringify(user))}
            }
            newState = user
            return newState
        default:
            return state
    }
}