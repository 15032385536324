import React, { useReducer, useEffect, createContext, useState } from 'react'
import axios from 'axios'
import userReducer from "../reducers/user-reducer"
import { setUser } from '../actions'

export const UserContext = createContext()
const UserProvider = UserContext.Provider

export function UserWrapper({ children }) {
    const defaultUser = { username: "Guest" }
    const ISSERVER = typeof window === "undefined";
    const storedUser = !ISSERVER ? JSON.parse(localStorage.getItem("user")) : []
    const [user, dispatchUser] = useReducer(userReducer, storedUser || defaultUser || "")

    useEffect(() => {
        if (storedUser) {
            setTimeout(() => {
                axios.get("https://strapi.maintive.com/api/users/me", {
                    headers: { 
                        authorization: `Bearer ${storedUser.jwt}`
                    }
                }).then(response => {
                    dispatchUser(setUser({ ...response.data, jwt: storedUser.jwt}))
                }).catch(error => {
                    dispatchUser(setUser(defaultUser))
                })
            })
        }
    }, [])

    return (
        <UserProvider value={{ user, dispatchUser, defaultUser }}>
            {children}
        </UserProvider>
    )
}