import React, { useContext, useEffect, useState } from 'react'
import Layout from '../components/Layout'

import AddQuotes from '../components/Quotes/Add'
import { UserContext } from '../context'
import LoginForm from '../components/loginForm'
import axios from 'axios'
import DisplayQuotations from '../components/Quotes/showAllQuotation'

const Quotation = () => {
  const { user } = useContext(UserContext)
  const [showQuotation, setShowQuotation] = useState(false)
  const [allQuotation, setAllQuotation] = useState([])
  useEffect(() => {
    getAllQuotation()
  }
    , [])

  const getAllQuotation = async () => {
    try {
      let result = await axios.get("/api/quotation", {
        headers: {
          "x-auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNWUwMzE1MDQyMmFhNGIwMDI4MTJjZDdmIn0sImlhdCI6MTY2NDk3NjUxOX0.wlsQHICTcv17qta-W_3oRSfhbkOmIsrlZ6DS-7ZvyQk",
          "api-key": "de1fbd60-b095-40fc-a040-af58528a9164",
          "secret-key": "4b8c1f3b-ce31-4ea5-8395-a02fed773ab0"
        }
      })
      let data = result?.data?.data

      setAllQuotation(data)
    }
    catch (err) {

      console.log("Error in Product", err)
    }
  }

  console.log("All Quotes")
  return (
    <Layout>
      {!user.jwt ? <>
        <LoginForm />
      </>
        : <>
          <div className='mt-3 mb-3 flex justify-end w-[95%]' >
            <button className='bg-primary text-white p-3 rounded-md font-semibold' onClick={() => setShowQuotation(!showQuotation)}>{showQuotation ? "Cancel" : "Add Quotes"}</button>
          </div>
          {showQuotation ? <AddQuotes /> : (
            <div className='w-[90%] mx-auto'>
              <DisplayQuotations allQuotation={allQuotation} />
            </div>
          )

          }

          {/* <QuotationTable /> */}
        </>}
    </Layout>
  )
}

export default Quotation