import React from 'react'
import Layout from '../components/Layout'
import { Link } from "react-router-dom"

export default function Home() {
  return (
    <Layout>
      <button className="border border-black rounded w-32 p-2 m-auto">
        <Link to="/orders">List of Orders</Link>
      </button>
    </Layout>
  )
}
