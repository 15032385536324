import React from 'react'


const QuotationTable = ({ items = [] }) => {

    const i = 0
    return (
        <div>
            {/* <!-- TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com -->  */}
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full text-left text-sm font-light">
                                <thead
                                    className="border-b bg-white font-medium dark:border-neutral-500 dark:bg-neutral-200">
                                    <tr>
                                        <th scope="col" className="px-6 py-4    underline">Description</th>
                                        <th scope="col" className="px-6 py-4    underline">Image</th>
                                        <th scope="col" className="px-6 py-4    underline">Quantity</th>
                                        <th scope="col" className="px-6 py-4    underline">Rate</th>
                                        <th scope="col" className="px-6 py-4    underline">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items?.map((product, index) => {


                                        return (
                                            <tr key={i} className={
                                                'border border-black  '
                                                + (index % 2 == 0 ? 'bg-slate-100 ' : '')
                                                // + (order.attributes.status === "NEW" ? 'text-blue-600 ' : '')
                                            }
                                            >
                                                <td className="px-6 py-4 border-r border-black font-[400]">
                                                    <>
                                                        <div>
                                                            <b>{index + 1}). </b> <span>{product?.product?.model}</span>
                                                            <span>{product?.product?.searchTitle}</span>
                                                        </div>
                                                        <div>
                                                            <b className='font-[650]'>Brand:</b><span className=''> {product?.product?.brand}</span>
                                                            <b className='font-[650]'> Model No : </b> <span> {product?.product?.model}</span>
                                                        </div>
                                                        <div>
                                                            <b className='font-[650]'>Dimension</b> :{product?.product?.packageDimension?.length ? <><span> {product?.product?.packageDimension?.length} * {product?.product?.packageDimension?.width} * {product?.product?.packageDimension?.height}</span> </> : " - "}
                                                            <b className='font-[650]'>Weight : </b> <span> {product?.product?.weight} kg</span>
                                                        </div>
                                                        <div>
                                                            <b className='font-[650]'>Warranty:</b><span> {product?.product?.warranty}</span>
                                                        </div>
                                                        {product?.calibrationPrice?
                                                            <div>
                                                                <span className='font-[400] text-[rgb(37,94,169)]'>With Calibration</span><span> <b>AED {product?.calibrationPrice}</b></span>
                                                            </div>
                                                            :""
                                                         }
                                                    </>
                                                </td>

                                                <td className="px-6 py-4 border-r border-black font-[400]">{product?.product?.images?.data ? <img src={product?.product?.images.data[0].attributes?.url} alt="product" width="80px" height="80px" /> : ""}</td>
                                                <td className="px-6 py-4 border-r border-black font-[400]">{product?.qty}</td>
                                                <td className="px-6 py-4 border-r border-black font-[400]">{product?.product.basePrice + (+product.calibrationPrice || 0)}</td>
                                                <td className="px-6 py-4 border-r border-black font-[400]">{((+product?.product.basePrice +(+product.calibrationPrice ||0) )* (+product?.qty))}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuotationTable