import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Orders from "./pages/Orders"
import { UserWrapper } from './context';
import Login from './pages/Login';
import Details from './pages/Details'
import Carts from './pages/Carts';
import Quotation from './pages/Quotation';
import 'react-toastify/dist/ReactToastify.css';
import GetSingleQuote from './pages/QuotesId';
import DownloadQuotation from './pages/download';
export default function App() {
  return (
    <UserWrapper>
      <BrowserRouter>
        <Routes>
            <Route index element={<Home />} />
            <Route path="/orders/:slug" element={<Details />} />
            <Route path="/orders" element={<Orders />}/>
            <Route path="/Login" element={<Login />}/>
            <Route path="/carts" element={<Carts />}/>
            <Route path="/quotation" element={<Quotation />}/>
            <Route path="/quotation/:id" element={<GetSingleQuote />}/>
            <Route path="/download-quotation/:id" element={<DownloadQuotation />}/>
        </Routes>
      </BrowserRouter>      
    </UserWrapper>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
