import React, { useState } from 'react'
import axios from 'axios'

function CartRow({ cart, i }) {
    const currentReason = cart.attributes.reason
    const currentComments = cart.attributes.comments

    const [newComments, setNewComments] = useState(currentComments)
    const [newReason, setNewReason] = useState(cart.attributes.reason)
    const possibleReasons = ["-", "other", "stock", "price", "deliveryTime"]

    const updateCart = (field, value) => {
        const updateData = {
            id: cart.id,
            [field]: value,
        };
        axios.put("/api/carts/update", {
            data: updateData,
        }, {
            headers: {
                "x-auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNWUwMzE1MDQyMmFhNGIwMDI4MTJjZDdmIn0sImlhdCI6MTY2NDk3NjUxOX0.wlsQHICTcv17qta-W_3oRSfhbkOmIsrlZ6DS-7ZvyQk",
                "api-key": "de1fbd60-b095-40fc-a040-af58528a9164",
                "secret-key": "4b8c1f3b-ce31-4ea5-8395-a02fed773ab0"
            }
        }).then(res => {
            window.location = "/carts"
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <tr key={cart.id} className={
            ' border border-black '
            + (i % 2 == 0 ? 'bg-slate-100 ' : '')
        }>
            <td className='p-2'>{cart.id}</td>
            <td>{cart.attributes.customerEmail}</td>
            <td>{cart.attributes.customerNumber}</td>
            <td className='font-semibold'>
                <ul>
                    {cart.attributes.items.map((item, i) => (
                        <li key={i}>{i + 1}. {item.product.brand} {item.product.model}</li>
                    ))}
                </ul>
            </td>
            <td>AED {cart.attributes.subtotal}</td>
            <td className='flex flex-col gap-2 items-center'>
                <select
                    value={newReason}
                    onChange={e => setNewReason(e.target.value)}
                    className='border border-black px-2 py-1 rounded-sm'
                >
                    {possibleReasons.map((reason, i) => (
                        <option key={i} value={reason}>{reason}</option>
                    ))}
                </select>
                <button onClick={() => updateCart('reason', newReason)} className={newReason !== currentReason ? " mx-auto border block my-2 rounded p-1 bg-primary text-white capitalize " : "hidden"}>update</button>
            </td>
            <td>
                <textarea 
                    type="text" 
                    className='border border-black text-sm w-full p-2' 
                    rows={4} 
                    placeholder='Comments' 
                    value={newComments}
                    onChange={e => setNewComments(e.target.value)}
                />
                <button onClick={() => updateCart('comments', newComments)} className={newComments !== currentComments ? " mx-auto border block my-2 rounded p-1 bg-primary text-white capitalize " : "hidden"}>update</button>
            </td>
        </tr>
    )
}

export default CartRow