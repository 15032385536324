import React, { useEffect } from 'react'
import logo from '../../assets/logo.png'
import QuotationTable from './QuotationTable'
import { useNavigate } from 'react-router-dom'

const Detail = ({ quotation }) => {
    const navigate = useNavigate()
    const dateFormatter = () => {
        // Input date in the given format
        var inputDateStr = quotation?.date;

        // Convert input string to Date object
        var inputDate = new Date(inputDateStr);

        // Create an array of month names for formatting
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Format the Date object in the desired output format
        var outputDateStr = inputDate.getDate() + " " + monthNames[inputDate.getMonth()] + " " + inputDate.getFullYear();

        console.log(outputDateStr);
        return outputDateStr;

    }
    function roundToDecimal(number, decimalPlaces = 2) {
        const factor = 10 ** decimalPlaces;
        return Math.round(number * factor) / factor;
    }
    return (
        <div className='w-[90%] mx-auto mt-4'>
            <div className='flex flex-cols justify-between m-auto '>
                <div className=' flex items-start w-[30%] '>
                    <img src={logo} alt="logo" width="280px" height="280px" onClick={() => navigate("/")} />
                </div>
               
                <div className='flex flex-col gap-1 w-[37%] '>
                    <h2 className='text-black font-bold text-lg text-right'>ALI ASGER & BROTHERS LLC</h2>
                    <p className='text-sm text-right'>Maintive, Street 43, Community 598, DIP 1 - UAE.</p>
                    <p className='text-sm text-right'>Tel: +971 4 802 9010</p>
                    <p className='text-sm text-right'>help@maintive.com</p>
                    <p className='text-sm text-right'>https://www.maintive.com/</p>
                    <p className='text-sm text-right'>TRN: 100604921500003</p>
                </div>
            </div>
            <div className='flex justify-center'><p className='text-2xl font-bold w-[30%] justify-end'>QUOTATION</p></div>

            {/* border */}

            <div className='border-2 mt-6'>
                <div className='flex justify-between w-[95%] mx-auto gap-4 mt-2'>
                    <div className='w-full'>
                        <div className='flex flex-col justify-evenly border-2 w-full h-[200px]'>
                            <h5 className=' bg-gray-400'><span className='ml-3'>To</span></h5>
                            <div className='ml-3'>
                                <p className='font-semibold text-base'>{quotation?.clientName}</p>
                                <p className='text-base'>{quotation?.clientAddress}</p>
                                <p className='text-base'>{quotation?.city}</p>
                                <p className='text-base'>{quotation?.country}</p>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <table>
                                <thead>
                                    <tr className='border-1'>
                                        <th className='border-2 p-2'> Payment Terms</th>
                                        <th className='border-2 p-2'> Quotation Validity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='border-1'>
                                        <td className='border-2 p-2'>{quotation?.paymentTerm}</td>
                                        <td className='border-2 p-2'> 7 Days</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='flex flex-col justify-evenly border-2 w-full h-[300px] mt-0.5'>
                        <h5 className=' bg-gray-400'><span className='ml-3'>Information</span></h5>
                        <div className='flex flex-col ml-3 gap-4'>
                            <div className='flex gap-4'>
                                <span className='flex flex-1'>Date </span> <span className='flex flex-1'>{dateFormatter()}</span>
                            </div>
                            <div className='flex gap-4'>
                                <span className='flex flex-1'>Quotation Number: </span> <span className='flex flex-1'>{quotation?.quotationNo}</span>
                            </div>
                            <div className='flex gap-4'>
                                <span className='flex flex-1'>RFQ Number: </span> <span className='flex flex-1'>{quotation?.rfqNo}</span>
                            </div>
                            <div className='flex gap-4'>
                                <span className='flex flex-1'>Your Reference </span> <span className='flex flex-1'></span>
                            </div>
                            <div className='flex gap-4'>
                                <span className='flex flex-1'>From </span> <span className='flex flex-1'>Maintive</span>
                            </div>
                            <div className='flex gap-4'>
                                <span className='flex flex-1'>Email </span> <span className='flex flex-1'>help@maintive.com</span>
                            </div>

                        </div>

                    </div>



                </div>

                <div className='w-[95%] m-auto'>
                    <QuotationTable items={quotation?.Items || []} />
                </div>



                <div className=' flex space-between w-[95%] mx-auto mb-6 mt-6'>
{/* 
                    <div className='flex  flex-1 w-full mt-2'>
                        <table className='w-[90%]'>
                            <thead>
                                <tr className='border-1'>
                                    <th colspan="2" className='border-2 p-2 '> Bank Details for Direct Remittances</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr className='border-1'>
                                    <th className='border-2 p-2'> Account</th>
                                    <td className='border-2 p-2'>ALI ASGER & BROTHERS LLC</td>
                                </tr>
                                <tr>
                                    <th className='border-2 p-2'> Account</th>
                                    <td className='border-2 p-2'> ABU DHABI COMMERCIAL BANK</td>
                                </tr>
                                <tr>
                                    <th className='border-2 p-2'> Bank</th>
                                    <td className='border-2 p-2'> ABU DHABI COMMERCIAL BANK</td>
                                </tr>
                                <tr>
                                    <th className='border-2 p-2'> IBAN AED</th>
                                    <td className='border-2 p-2'> AE86 0030 0104 6437 9810 001</td>
                                </tr>
                                <tr>
                                    <th className='border-2 p-2'> SWIFT</th>
                                    <td className='border-2 p-2'> ADCBAEAA</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                    <div className='flex-1 flex'></div>

                    <div className='flex  flex-1 w-full mt-2 justify-end'>
                        <table className='w-[90%] flex justify-end'>
                            <thead>
                                <tr className='border-1'>



                                </tr>
                            </thead>
                            <tbody>
                                <tr className='border-1'>
                                    <th className='border-2 p-2 text-left'> Sub-Total <sub>(excluding VAT)</sub></th>
                                    <td className='border-2 p-2 text-left'>AED &nbsp;{roundToDecimal(quotation?.subTotal- (+quotation?.VATAmount))}</td>
                                </tr>
                                <tr>
                                    <th className='border-2 p-2 text-left'> Shipping & Handling <sub>(excluding VAT)</sub></th>
                                    <td className='border-2 p-2 text-left'>  AED {roundToDecimal(quotation?.shippingFee) || "-"}</td>
                                </tr>
                                <tr>
                                    <th className='border-2 p-2 text-left'> Discount <sub> (excluding VAT) </sub></th>
                                    <td className='border-2 p-2 text-left'> {roundToDecimal(quotation?.discount) || "-"}</td>
                                </tr>
                                <tr>
                                    <th className='border-2 p-2 text-left'> Applicable VAT</th>
                                    <td className='border-2 p-2 text-left'> AED {roundToDecimal(quotation?.VATAmount) || "-"}</td>
                                </tr>
                               
                                <tr>
                                    <th className='border-2 p-2 text-left'> Total Amount Inclusive of VAT</th>
                                    <td className='border-2 p-2 text-left'> AED {roundToDecimal(quotation?.total + (+quotation?.shippingFee))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Detail