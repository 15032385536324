import axios from 'axios'
import React, { useState } from 'react'

function FormShipping({ name, email, number, shippingAddress, company, shippingDetails, id }) {
    const [loading, setLoading] = useState(false)

    const [newName, setNewName] = useState(name)
    const [newEmail, setNewEmail] = useState(email)
    const [newNumber, setNewNumber] = useState(number)
    const [newShippingAddress, setNewShippingAddress] = useState(shippingAddress)
    const [newCompany, setNewCompany] = useState(company)

    const updateDetails = () => {
        let newShippingDetails = shippingDetails
        newShippingDetails = {
            ...newShippingDetails,
            name: newName,
            email: newEmail,
            number: newNumber,
            company: newCompany,
            shippingAddress: newShippingAddress
        }
        console.log("Update Details")
        setLoading(true)
        axios.put("https://strapi.maintive.com/api/orders/" + id, {
            data: {
                shippingDetails: newShippingDetails,
                company: newCompany,
                shippingAddress: newShippingAddress,
                customerName: newName,
                customerEmail: newEmail,
                customerNumber: newNumber
            }
        }, {
            headers: {
                Authorization: `Bearer 4a755c68f0bba275a9f3fbcdaf3b0253b49ee5f31fa50ac9db02dcf06b5f90a0a774472ba9b581a4ca8c761d2fc977eee17aeb67b32bab63329d345ddb6d2ef96999f335781dfe74915ab287275d932c06151b9ada47e7bc4e3d55e3970582c2c3d3cbcfb73a464b18d3cb811e8cb07f66d4c02512fa57f8d6f3e69052969f0c`
            }
        }).then(res => {
            window.location.reload()
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    return (
        <form onSubmit={e => { e.preventDefault(); updateDetails() }} className='w-full p-4 flex flex-col gap-2'>
            <input value={newName} onChange={e => setNewName(e.target.value)} placeholder='Name' type="text" className='input' />
            <input value={newEmail} onChange={e => setNewEmail(e.target.value)} placeholder='Email' type="text" className='input' />
            <input value={newNumber} onChange={e => setNewNumber(e.target.value)} placeholder='New Number' type="text" className='input' />
            <input value={newShippingAddress} onChange={e => setNewShippingAddress(e.target.value)} placeholder='New Shipping Address' type="text" className='input' />
            <input value={newCompany} onChange={e => setNewCompany(e.target.value)} placeholder='Company' type="text" className='input' />
            {loading ?
                <div>Loading...</div>
                :
                <button className='bg-primary text-white p-2 rounded-md font-semibold'>Save</button>
            }
        </form>
    )
}

export default FormShipping