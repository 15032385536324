import React from 'react'
import { Link } from 'react-router-dom'


const DisplayQuotations = ({ allQuotation }) => {

    const items = []
    const i = 0
    return (
        <div>
            {/* <!-- TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com -->  */}
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full text-left text-sm font-light">
                                <thead
                                    className="border-b bg-white font-medium dark:border-neutral-500 dark:bg-neutral-200">
                                    <tr>
                                        <th scope="col" className="px-6 py-4">Quotation No.</th>
                                        <th scope="col" className="px-6 py-4">Customer Name</th>
                                        <th scope="col" className="px-6 py-4">Country</th>
                                        <th scope="col" className="px-6 py-4">Qty</th>
                                        <th scope="col" className="px-6 py-4">subTotal</th>
                                        <th scope="col" className="px-6 py-4">Discount</th>
                                        <th scope="col" className="px-6 py-4">Total</th>
                                        <th scope="col" className="px-6 py-4">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allQuotation?.map((product, index) => {
                                        const totalQuantity = product.attributes.Items.reduce((accumulator, item) => {
                                            const itemQuantity = parseInt(item.qty); // Extract the quantity for the item
                                            return accumulator + itemQuantity;
                                        }, 0);

                                        return (
                                            <tr key={index} className={
                                                'border border-black  '
                                                + (index % 2 == 0 ? 'bg-slate-100 ' : '')
                                                // + (order.attributes.status === "NEW" ? 'text-blue-600 ' : '')
                                            }
                                            >
                                                <td className="px-6 py-4 font-[400]">{product.attributes.quotationNo}</td>
                                                <td className="px-6 py-4 font-[400]">{product.attributes.clientName}</td>
                                                <td className="px-6 py-4 font-[400]">{product?.attributes.clientCountry}</td>
                                                <td className="px-6 py-4 font-[400]">{totalQuantity}</td>
                                                <td className="px-6 py-4 font-[400]">{product?.attributes.subTotal}</td>
                                                <td className="px-6 py-4 font-[400]">{product?.attributes.Discount}</td>
                                                <td className="px-6 py-4 font-[400]">{product?.attributes.total}</td>
                                                <td className="px-6 py-4 font-[400]"><Link to={`/quotation/${product.id}`}><button className='bg-primary text-white p-3 rounded-md font-semibold' >View Detail</button></Link></td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisplayQuotations