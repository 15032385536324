import React from 'react'
import { Link } from 'react-router-dom'
import { BsChevronRight } from 'react-icons/bs'

function SideBar({ showMenu }) {

    return (
        <>
            <div className={
                "h-full z-20 fixed transition-all duration-500 bg-white w-72 border shadow-lg flex flex-col py-2 "
                +
                (showMenu ? "left-0" : "-left-72")
            }
            >
                <Link to="/orders" className='p-4 flex justify-between border-b'>
                    <span>Orders</span>
                    <BsChevronRight />
                </Link>
                <Link to="/carts" className='p-4 flex justify-between border-b'>
                    <span>Carts</span>
                    <BsChevronRight />
                </Link>
                <Link to="/orders" className='p-4 flex justify-between border-b'>
                    <span>Bulk update</span>
                    <BsChevronRight />
                </Link>
                <Link to="/quotation" className='p-4 flex justify-between border-b'>
                    <span>Quotation</span>
                    <BsChevronRight />
                </Link>
            </div>
        </>
    )
}

export default SideBar