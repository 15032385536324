import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../context'
import Layout from '../components/Layout'
import axios from "axios"
import OrderTable from '../components/OrderList/OrderTable'
import LoginForm from "../components/loginForm"

export default function Orders() {
    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])
    const { user } = useContext(UserContext)

    useEffect(() => {
        // axios
        axios.get("/api/orders", {
            headers: {
                "x-auth-token": process.env.OMS_XAUTHTOKEN,
                "api-key": process.env.OMS_APIKEY,
                "secret-key": process.env.OMS_SECRETKEY
            }
        })
            .then(res => {
                setOrders(res.data.orders)
            }).catch(err => {
                console.log("Error: ", err)
            })
        setLoading(false)
    }, [])

    return (
        <Layout>
            {!user.jwt ?
                <LoginForm />
                :
                loading || orders.length < 1 ? <span>Loading...</span>
                    :
                    <OrderTable orders={orders} />
            }
        </Layout>
    )
}
