import React, { useState, useContext } from 'react'
import axios from 'axios'
import { UserContext } from '../context'
import { setUser } from '../context/actions'
import { Navigate } from 'react-router-dom'

export default function LoginForm() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false)

    const [showReset, setShowReset] = useState(false)

    const { user, dispatchUser } = useContext(UserContext)

    const handleLogin = (email, password, event) => {
        event.preventDefault()
        setLoading(true)
        setError("")
        axios.post("https://strapi.maintive.com/api/auth/local/", {
            identifier: email,
            password: password
        }, {
            headers: {
                Authorization: `Bearer 4a755c68f0bba275a9f3fbcdaf3b0253b49ee5f31fa50ac9db02dcf06b5f90a0a774472ba9b581a4ca8c761d2fc977eee17aeb67b32bab63329d345ddb6d2ef96999f335781dfe74915ab287275d932c06151b9ada47e7bc4e3d55e3970582c2c3d3cbcfb73a464b18d3cb811e8cb07f66d4c02512fa57f8d6f3e69052969f0c`
            }
        }).then(response => {
            if (response.data.user.admin) {
                dispatchUser(setUser({ ...response.data.user, jwt: response.data.jwt }))
            } else {
                setError("Not Admin")
            }
            //navigate("/")
            setLoading(false)
        }).catch(error => {
            setError("try again")
            setLoading(false)
        })
        setLoading(false)
    }

    return (
        <div className='w-1/3 m-auto text-center'>
            <h1 className='capitalize text-xl md:text-3xl font-bold pb-2 md:pb-5'>{showReset ? "Reset Your Password" : "Login"}</h1>
            {!showReset ?
                <form action='submit' onSubmit={e => handleLogin(email, password, e)}>
                    <input
                        disabled={loading}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="bg-transparent my-3 w-full  py-2 pl-4 border-black rounded-md border placeholder-black focus:outline-none "
                    />
                    <input
                        disabled={loading}
                        value={password}
                        onChange={e => setPassword(e.target.value)}

                        type="password"
                        name="password"
                        placeholder="Password"
                        className="bg-transparent my-3 w-full  py-2 pl-4 border-black rounded-md border placeholder-black focus:outline-none "
                    />
                    <span className='text-red-500 w-full'>{error}</span> <br />

                    <button
                        disabled={loading}
                        type="submit"
                        className='my-4 bg-black font-semibold text-sm capitalize w-full bg-split-primary text-white py-2 rounded hover:bg-white hover:border-black hover:text-black disabled:bg-tertiary'
                    >
                        {loading ? "Please Wait" : "Login"}
                    </button>
                </form>
                :
                <div>
                    <button className='flex items-center text-primary gap-2 my-2' onClick={() => setShowReset(false)}>
                        Login
                    </button>
                </div>
            }
        </div>
    )
}
