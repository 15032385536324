import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import QuotationTable from './QuotationTable'
import QuotesBill from './bill'
import { ToastContainer, toast } from 'react-toastify';
import { UserContext } from '../../context'
import { useNavigate } from 'react-router-dom';

const AddQuotes = () => {
    const { user } = useContext(UserContext)
    const [products, setProducts] = useState([])
    const navigate = useNavigate()

    const [client, setClient] = useState({
        name: '',
        country: '',
        city: '',
        address: '',
        shippingFee: 0,
        paymentTerm: 'Prepaid Online',
        calibration: false,
        calibrationPrice:0,
        RfqNo: '',
    })
    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState(null)
    const [qty, setQty] = useState(0)
    const [items, setItems] = useState([])
    const [discount, setDiscount] = useState(0)
    const [discountedValue, setDiscountedValue] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [vatAmount, setVatAmount] = useState(0)
    const [quotationNo, setQuotationNumber] = useState(Math.floor(Math.random() * 900000) + 100000)
    const [search, setSearch] = useState('')
    const [isCal, setIsCal] = useState(false)
    const [calibrationPrice, setCalibrationPrice] = useState(0)
    const handleInputChange = (value) => {
        setSearch(value)
    }
    const getAllProducts = async () => {
        setLoading(true)
        try {
            let result = await axios.get(`/api/products?search=${search}`, {
                headers: {
                    "x-auth-token": process.env.OMS_XAUTHTOKEN,
                    "api-key": process.env.OMS_APIKEY,
                    "secret-key": process.env.OMS_SECRETKEY
                }
            })
            let data = result.data.products || []

            let optionData = data.map((d1) => {

                return { label: `${d1.attributes.model} - ${d1.attributes.brand} - ${d1.attributes.searchTitle}`, value: d1.attributes }
            })
            setLoading(false)
            setProducts(optionData)
        }
        catch (err) {
            setLoading(false)
            console.log("Error in Product", err)
        }
    }
    useEffect(() => {
        const totalAmount = items.reduce((accumulator, currentItem) => accumulator + (currentItem.totalPrice), 0);

        const VAT = (totalAmount - (totalAmount / 1.05))

        let total = (totalAmount - discountedValue)


        setTotal(total)
        setSubTotal(totalAmount)
        setVatAmount(VAT)

    }, [JSON.stringify(items), discountedValue])

    useEffect(() => {
        // axios
        getAllProducts()

    }, [search])


    const changeValue = (value) => {

        setProduct(value)
        console.log("product is" , typeof value)
     
        setIsCal(value.value.calibration);
        
        const calPrice = value.value.calibrationPrice
        setCalibrationPrice(calPrice)
        
        
        
    }
    const addProduct = (e) => {

        if (!product || !qty) {
            return alert("Please add a product and qty.")
        }
        const productData = {
            qty: qty,
            product: product.value,
            totalPrice: qty * (product.value.basePrice + client.calibrationPrice),
            calibrationPrice: client.calibrationPrice ||0
        }

        setItems([...items, productData])
        setProduct(null)
        setQty(0)
        setIsCal(false);


    }
    const dicountApply = () => {

        setDiscountedValue(discount)
        setDiscount(0)
    }

    const CalibrationPriceSet = (calibrationActive) => {
        if(calibrationActive){
            setClient((prevClient) => ({
                ...prevClient,
                calibrationPrice: calibrationPrice
            }));
        }else{
            setClient((prevClient) => ({
                ...prevClient,
                calibrationPrice: 0
            }));
        }

    }
    const changeClientHandler = (attribute, e) => {
        const { value } = e.target
        
        if(attribute === "calibration"){
            const calibrationActive= !client.calibration
            
            CalibrationPriceSet(calibrationActive);

            setClient((prevClient) => ({
                ...prevClient,
                calibration: calibrationActive
            }));
        }
       
        else{
            setClient((prevClient) => ({
                ...prevClient,
                [attribute]: value
            }));
        }
    };

    
    const isClientValid = () => {
        // Check if all values in the client object are non-empty strings
        console.log( client ,"client");
        const response = Object.entries(client).every(([key, value]) => {
            // Check if the value is not 'calibration' or 'calibrationPrice'
            if (key === 'calibration' || key === 'calibrationPrice' || key === 'RfqNo' || key === 'shippingFee') {
              return true; // Ignore these properties
            }
            
            // Check if the value is a string and not empty after trimming
            return typeof value === 'string' && value.trim() !== '';
          });
        return response;
    };
    function generateUniqueNumber() {
        // Generate a random number between 100000 and 999999
        const randomNumber = Math.floor(Math.random() * 900000) + 100000;

        return randomNumber;
    }




    const saveFormDb = async () => {
        if (!isClientValid()) {

            return toast.error("Please fill all client information.")
        }

        const dateObj = new Date();
        const dateFormat = dateObj.toISOString().split('T')[0];
        const data = {
            clientName: client.name,
            city: client.city,
            clientCountry: client.country,
            clientAddress: client.address,
            shippingFee: client.shippingFee,
            paymentTerm: client.paymentTerm,
            calibration:client.calibration,
            calibrationPrice: client.calibration?client.calibrationPrice:0,
            date: dateFormat,
            total: total,
            subTotal: subTotal,
            Discount: +discountedValue || 0,
            VATAmount: vatAmount,
            rfqNo: client.RfqNo,
            Items: items,
            quotationNo: quotationNo + "",
            representative: user.id
        }

        let result = await axios.post("/api/quotation", {
            data: data
        }, {
            headers: {
                "x-auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNWUwMzE1MDQyMmFhNGIwMDI4MTJjZDdmIn0sImlhdCI6MTY2NDk3NjUxOX0.wlsQHICTcv17qta-W_3oRSfhbkOmIsrlZ6DS-7ZvyQk",
                "api-key": "de1fbd60-b095-40fc-a040-af58528a9164",
                "secret-key": "4b8c1f3b-ce31-4ea5-8395-a02fed773ab0"
            }
        })

        if (result?.data?.success) {
            const id = result?.data?.success?.data?.id
            toast.success("Quotation added successfully")

            setTimeout(() => {
                navigate(`/quotation/${id}`)
            }, [1000])
        }

    }

    console.log("client.calibration"+ JSON.stringify(client))
    return (
        <div className='mt-4'>
            <div className='flex justify-between w-[95%] m-auto mb-8'>
                <div className='flex gap-3 items-center ml-24 flex-3 '>
                    <>
                    <h2>Quotation No.</h2>
                    <p className='border-b-4 text-base font-bold'>{quotationNo}</p>
                    </>
                  
                </div>
                <div className='flex   justify-center flex-3'>
                    <div className='flex flex-1 '>
                    <h2>RFQ No.</h2>
                    </div>
                    <div className='flex flex-3 ml-1'>
                    <input className="border border-gray-400  w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="RFQ No." aria-label="RFQ No." width={'50px'} value={client.RfqNo} onChange={(e) => changeClientHandler("RfqNo", e)}/>
                    </div>
                    </div>
                <div>
                    <button className={`${items.length > 0 ? 'bg-primary text-white' : 'cursor-not-allowed bg-gray-50 text-gray-500'}  p-3 rounded-md font-semibold`} disabled={items.length > 0 ? false : true} onClick={() => saveFormDb()}>Save</button>
                </div>
            </div>
            <div class="w-[80%] m-auto mt-2">
                {/* client Detail */}

                <div className='flex gap-4 '>

                    <div className='  w-[70%]  client_detail border-b-4 mb-2 pr-4 border-r-4'>
                        <span className='block text-primary text-base font-[700]'>Client Info</span>
                        <br />
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Client Name
                                </label>
                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Name" value={client.name} onChange={(e) => changeClientHandler("name", e)} />
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                    Country
                                </label>
                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Country" value={client.country} onChange={(e) => changeClientHandler("country", e)} />
                            </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    City
                                </label>
                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="city" value={client.city} onChange={(e) => changeClientHandler("city", e)} />
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                    Address
                                </label>
                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Address" value={client.address} onChange={(e) => changeClientHandler("address", e)} />
                            </div>
                        </div>

                        {/*  */}
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Payment Term
                                </label>
                                <select
                                    value={client.paymentTerm}
                                    onChange={e => changeClientHandler("paymentTerm", e)}
                                    name="paymentTerm"
                                    className='border border-black rounded-sm w-full p-[8px]'
                                >
                                    <option value="Prepaid Online">Prepaid online</option>
                                    <option value="COD">COD</option>


                                </select>
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                    Shipping Charges
                                </label>
                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="number" placeholder="Shipping Charges" value={client.shippingFee} onChange={(e) => changeClientHandler("shippingFee", e)} />
                            </div>
                        </div>
                    </div>

                    {/* General info */}

                    <div className=' w-[30%] client_detail border-b-4 mb-2'>

                        {items.length >= -1 ? <>
                            <div className='w-full flex justify-end'>
                                <div className='w-[500px] flex items-center '>

                                    <div class="w-2/3 px-3 mb-6 md:mb-0">
                                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                            Discount (AED)
                                        </label>
                                        <input
                                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="grid-first-name"
                                            type="number"
                                            placeholder="Enter the discount amount"
                                            value={discount} // Use 'value' instead of 'defaultValue'
                                            onChange={(e) => setDiscount(e.target.value)}
                                        />                        </div>
                                    <div class="w-1/3 mt-3  md:mb-0">
                                        <button className={`${discount ? 'bg-primary text-white' : 'cursor-not-allowed bg-gray-50 text-gray-500'}  p-3 rounded-md font-semibold`} disabled={discount ? false : true} onClick={() => dicountApply()}>Apply</button>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex justify-end'>
                                <div className='w-[500px]  '>
                                    <QuotesBill totalPrice={total} subTotal={subTotal} VAT={vatAmount} discount={discountedValue} shippingCharges={client.shippingFee} />
                                    <br /><br />
                                </div>
                            </div>

                        </>
                            : ""
                        }
                    </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            Select Product
                        </label>
                        <Select
                            onInputChange={handleInputChange}
                            options={products}
                            defaultValue={product}
                            onChange={changeValue}
                            key={product ? product : ''}
                            isLoading={loading}
                        />



                    </div>
                    <div class="w-full md:w-1/2 px-3">
                        <div className=' flex gap-2'>
                            <div className='w-full md:w-2/3' >
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                    Qty.
                                </label>
                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="number" value={qty} onChange={(e) => setQty(e.target.value)} placeholder="Doe" />
                            </div>
                            <div className='w-full md:w-1/3 flex items-center mt-6'>
                                <button className='bg-primary text-white p-2 rounded-md font-semibold' onClick={() => addProduct()}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                {isCal ?
                    <div class=" mb-6">
                        <div class="md:w-1/3"></div>
                        <label class="md:w-2/3 block text-gray-500 font-bold">
                            <input class="mr-2 leading-tight" type="checkbox"   checked={client.calibration} onChange={(e)=>changeClientHandler("calibration",e)} />
                            <span class="text-sm">
                                Add Certified Calibration (AED {calibrationPrice})
                            </span>
                        </label>
                    </div>
                    : ""
                }
                <div>


                </div>

                

                {/* Dsplay Product */}
                {items.length >= 1 ? <div className='mb-4'>


                    <QuotationTable items={items} />
                </div>
                    : ""
                }
            </div>
            <ToastContainer />
        </div>
    )
}

export default AddQuotes