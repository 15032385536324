import React from 'react'
import Header from "./Header"
import Footer from "./Footer"

export default function Layout({ children }) {
  return (
    <div className='flex flex-col min-h-screen text-sm 2xl:text-base'>
        <Header />
        {children}
    </div>
  )
}
