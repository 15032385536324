import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { useParams } from 'react-router-dom'
import Detail from '../components/Quotes/Detail'
import axios from 'axios'

const GetSingleQuote = () => {

    const { id } = useParams()
    const [quotation, setQuotation] = useState(null)

    useEffect(() => {
        getSingleQuotation()
    }, [])
    useEffect(() => {
        if (quotation) {
            setTimeout(() => {
                window.print()
            }, [1000]
            )
        }
    }, [JSON.stringify(quotation)])
    const getSingleQuotation = async () => {
        try {
            let result = await axios.get(`/api/quotation/${id}`, {
                headers: {
                    "x-auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNWUwMzE1MDQyMmFhNGIwMDI4MTJjZDdmIn0sImlhdCI6MTY2NDk3NjUxOX0.wlsQHICTcv17qta-W_3oRSfhbkOmIsrlZ6DS-7ZvyQk",
                    "api-key": "de1fbd60-b095-40fc-a040-af58528a9164",
                    "secret-key": "4b8c1f3b-ce31-4ea5-8395-a02fed773ab0"
                }
            })

            let data = result?.data?.data?.attributes


            setQuotation(data)
        }
        catch (err) {

            console.log("Error in Product", err)
        }
    }
    return (


        <Detail quotation={quotation} />

    )
}

export default GetSingleQuote  