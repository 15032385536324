import React from 'react'
import Layout from '../components/Layout'
import LoginForm from '../components/loginForm'

export default function Login() {
  return (
    <Layout>
        <LoginForm />
    </Layout>
  )
}
