import React from 'react'
import OrderRow from './OrderRow'

export default function OrderTable(props) {
    const { orders } = props

    return (
        <table className=''>
            <thead className='bg-slate-300'>
                <tr className='text-left'>
                    <th>Order ID</th>
                    <th>Customer Email</th>
                    <th>Items</th>
                    <th>QTY</th>
                    <th>Price (Incl Vat)</th>
                    <th>Payment Details</th>
                    <th>Payment Method</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody className=''>
                {orders.sort((a,b) => a.id > b.id ? -1 : 1)
                        //.filter(order => order.attributes.status !== "PAYMENTPENDING")
                        .map((order, i) => <OrderRow order={order} i={i} />)
                }
            </tbody>
        </table>
    )
}
