import React from 'react'

const QuotesBill = ({ totalPrice = 0, subTotal = 0, VAT = 0, discount = 0, shippingCharges = 0}) => {
    function roundToDecimal(number, decimalPlaces = 2) {
        const factor = 10 ** decimalPlaces;
        return Math.round(number * factor) / factor;
    }
    return (
        <div>
            <table className="w-full border border-collapse">
                <tbody>
                    <tr className=" flex w-full">
                        <td className="border border-black flex flex-1 p-2">Sub Total:</td>
                        <td className="border border-black flex flex-1 p-2">{roundToDecimal(subTotal -VAT) || "-"}</td>
                    </tr>
                    <tr className=" flex w-full">
                        <td className="border border-black flex flex-1 p-2">Discount:</td>
                        <td className="border border-black flex flex-1 p-2">{roundToDecimal(discount)  || "-"}</td>
                    </tr>
                    <tr className="flex w-full">
                        <td className="border border-black flex flex-1 p-2">Applicable VAT:</td>
                        <td className="border border-black flex flex-1 p-2">{roundToDecimal(VAT)  || "-"}</td>
                    </tr>
                    <tr className="flex w-full">
                        <td className="border border-black flex flex-1 p-2">Shipping Charges:</td>
                        <td className="border border-black flex flex-1 p-2">{totalPrice?roundToDecimal(shippingCharges) : "-"}</td>
                    </tr>
                    <tr className="flex w-full">
                        <td className="border border-black flex flex-1 p-2">Total Amount Inclusive of VAT:</td>
                        <td className="border border-black flex flex-1 p-2">{totalPrice?roundToDecimal(totalPrice + ( +shippingCharges)) : "-"}</td>
                    </tr>
                </tbody>
            </table>


        </div>
    )
}

export default QuotesBill