import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import FormBilling from '../components/forms/FormBilling';
import FormShipping from '../components/forms/FormShipping';

function Details() {
    const { slug } = useParams();
    const [loading, setLoading] = useState(true)
    const [order, setOrder] = useState({})
    const [error, setError] = useState(false)
    const [editBilling, setEditBilling] = useState(false)
    const [editShipping, setEditShipping] = useState(false)

    useEffect(() => {
        axios.get(`/api/orders/${slug}`, {
            headers: {
                "x-auth-token": process.env.OMS_XAUTHTOKEN,
                "api-key": process.env.OMS_APIKEY,
                "secret-key": process.env.OMS_SECRETKEY
            }
        }).then(res => {
            setOrder(res.data.order)
            setLoading(false)
        }).catch(err => {
            console.log("Error: ", err)
            setError(true)
        })
    }, [])

    const resetTracking = () => {
        axios.put("https://strapi.maintive.com/api/orders/" + order.id, {
            data: {
                trackingID: "",
                trackingURL: "",
                courier: "",
            }
        }, {
            headers: {
                Authorization: `Bearer 4a755c68f0bba275a9f3fbcdaf3b0253b49ee5f31fa50ac9db02dcf06b5f90a0a774472ba9b581a4ca8c761d2fc977eee17aeb67b32bab63329d345ddb6d2ef96999f335781dfe74915ab287275d932c06151b9ada47e7bc4e3d55e3970582c2c3d3cbcfb73a464b18d3cb811e8cb07f66d4c02512fa57f8d6f3e69052969f0c`
            }
        }).then(res => {
            window.location.reload()
            setLoading(false)
        })
    }

        function convertToGulfTime(utcDateString) {
            // Parse the input date string to a Date object
            let utcDate = new Date(utcDateString);

            // Create a new date object for Gulf Standard Time (UTC+4)
            let gulfTime = new Date(utcDate.getTime() + (4 * 60 * 60 * 1000));

            // Extract date components
            let day = String(gulfTime.getUTCDate()).padStart(2, '0');
            let month = String(gulfTime.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
            let year = gulfTime.getUTCFullYear();

            // Extract time components
            let hours = String(gulfTime.getUTCHours()).padStart(2, '0');
            let minutes = String(gulfTime.getUTCMinutes()).padStart(2, '0');

            // Format the date and time as "dd-mm-yyyy hour:min"
            let formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}`;

            return `${formattedDateTime} (Gulf Standard Time) `;
        }


    return (
        <Layout>
            {loading ?
                <span>{error ? "Error Occured" : "Loading..."}</span>
                :
                <div className='container mx-auto p-4'>
                    <h1>Order ID: {slug}</h1>
                    {/* Order details ------------------------------------------------- */}
                    <div className="mb-6 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8">

                        <div className="border">
                            <h2 className='bg-primary text-white p-2'>Order Summary</h2>
                            <ul className='p-2 flex flex-col gap-2'>
                                <li className='font-semibold'>Order Status: {order.attributes.status}</li>
                                <li>Purchase Date:  {convertToGulfTime(order?.attributes?.createdAt)}</li>
                                <li>Payment Method: {
                                    order.attributes.deliveryMethod == "Click & Collect" ?
                                        order.attributes.paymentMode === "COD" ? "Card on Delivery" :<> {order.attributes.paymentMode} &nbsp;
                                             {(order.attributes.paymentMode==="ONLINE"&&order.attributes.paymentDetails.payment_method)==="AMAZON"?<span className='font-bold'>(AMAZON)</span>:""}
                                             
                                            {order.attributes.paymentMode==="ONLINE"&&order?.attributes?.paymentDetails?.payment_method!=="AMAZON"?<span className='font-bold'>(NETWORK)</span>:""}
                                       </> :<> {order.attributes.paymentMode} &nbsp;
                                             {(order.attributes.paymentMode==="ONLINE"&&order.attributes.paymentDetails.payment_method)==="AMAZON"?<span className='font-bold'>(AMAZON)</span>:""}
                                              
                                            {order.attributes.paymentMode==="ONLINE"&&order?.attributes?.paymentDetails?.payment_method!=="AMAZON"?<span className='font-bold'>(NETWORK)</span>:""}
                                       </>
                                }
                                </li>
                                <li>Network ref: {order.attributes.networkRef}</li>
                                <li className='font-semibold'>Payment Status: {order.attributes.paymentStatus}</li>
                                <li>Order Shipping: {order.attributes.shipping}</li>
                                <li>Delivery Method: {order.attributes.deliveryMethod}</li>
                                <li className='font-semibold'>
                                    Order Total: 
                                    AED {order.attributes.total}
                                    {order.attributes.coupon ?
                                        <span className='bg-red-600 p-1 text-white mx-2 rounded text-xs'>
                                            Coupon: {order.attributes.coupon}, AED {order.attributes.couponValue}
                                        </span>
                                    :null}
                                    </li>
                            </ul>
                        </div>

                        <div className="border">
                            <div className="w-full flex justify-between bg-primary text-white px-2">
                                <h2 className='text-white'>Shipping Details</h2>
                                <button onClick={() => setEditShipping(!editShipping)}>Edit</button>
                            </div>
                            {editShipping ?
                                <FormShipping
                                    id={order.id}
                                    shippingDetails={order.attributes.shippingDetails}
                                    name={order.attributes.customerName}
                                    email={order.attributes.customerEmail}
                                    number={order.attributes.customerNumber}
                                    company={order.attributes.company}
                                    shippingAddress={order.attributes.shippingAddress}
                                />
                                :
                                <ul className='p-2 flex flex-col gap-2'>
                                    <li>Name: {order.attributes.customerName}</li>
                                    <li>Email: {order.attributes.customerEmail}</li>
                                    <li>Number: {order.attributes.customerNumber}</li>
                                    <li>Company: {order.attributes.company}</li>
                                    <li></li>
                                    <li>{order.attributes.customerCity}, {order.attributes.customerCountry}</li>
                                    <li>{order.attributes.shippingAddress}</li>
                                </ul>}
                        </div>

                        <div className="border">
                            <div className="w-full flex justify-between bg-primary text-white px-2">
                                <h2 className='text-white'>Billing Details</h2>
                                <button onClick={() => setEditBilling(!editBilling)}>Edit</button>
                            </div>
                            {editBilling ?
                                <FormBilling
                                    id={order.id}
                                    billingDetails={order.attributes.billingDetails}
                                    name={order.attributes.billingName}
                                    email={order.attributes.billingEmail}
                                    number={order.attributes.billingNumber}
                                    trn={order.attributes.vatNumber}
                                    billingAddress={order.attributes.billingAddress}
                                />
                                :
                                <ul className='p-2 flex flex-col gap-2'>
                                    <li>Name: {order.attributes.billingName}</li>
                                    <li>Email: {order.attributes.billingEmail}</li>
                                    <li>Number: {order.attributes.billingNumber}</li>
                                    <li>TRN: {order.attributes.vatNumber}</li>
                                    <li></li>
                                    <li>{order.attributes.billingCity}, {order.attributes.billingCountry}</li>
                                    <li>{order.attributes.billingAddress}</li>
                                </ul>}
                        </div>

                    </div>
                    {/* Order Items ---------------------------------------------------------- */}
                    <h2>Order Items</h2>
                    <table className='w-full text-left p-2'>
                        <tr className='bg-primary text-white'>
                            <th className='p-2'>Image</th>
                            <th>Product Details</th>
                            <th>Qty</th>
                            <th>Unit Price</th>
                        </tr>
                        {order.attributes.items.map(item => (
                            <tr>
                                <td>
                                    <img src={item.product.image} alt="" />
                                </td>
                                <td>
                                    <ul>
                                        <li>{item.product.model}</li>
                                        <li className='font-bold'>{item.product.searchTitle}</li>
                                        <li>{item.product.brand}</li>
                                        <li className='text-secondary font-bold'>{item.calibration}</li>
                                    </ul>
                                </td>
                                <td>{item.qty}</td>
                                <td>
                                    AED {item.price}
                                    {item.calibration ? <div>Calibration: AED {item.product.calibrationPrice}</div> : null}
                                </td>
                            </tr>
                        ))}
                    </table>
                    <h2>Logs</h2>
                    <div className="mb-6 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8">

                        <div className="border">
                            <h2 className='bg-primary text-white p-2'>Status History</h2>
                            <ul className='p-2 flex flex-col gap-2'>
                                {order.attributes.statusHistory.map(status => (
                                    <li className='flex flex-col'>
                                        <span>status: <span className='font-semibold'>{status.current || status.change}</span></span>
                                        <span>date: {convertToGulfTime(status?.date)}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="border">
                            <h2 className='bg-primary text-white p-2'>Email History</h2>
                            <ul className='p-2 flex flex-col gap-2'>
                                {order.attributes.emailLogs?.map((email, i) => (
                                    <li className='flex flex-col'>
                                        <span>{i + 1}. Email Subject: <span className='font-semibold'>{email.email}</span></span>
                                        <span>date: {email.date}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="border">
                        <div className="w-full flex justify-between bg-primary text-white px-2">
                                <h2 className='text-white'>Tracking Details</h2>
                                <button onClick={resetTracking}>Reset</button>
                            </div>
                            <ul className='p-2 flex flex-col gap-2'>
                                <li>Tracking ID: {order.attributes.trackingID}</li>
                                <li>Tracking URL: {order.attributes.trackingURL}</li>
                                <li>Courier: {order.attributes.courier}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </Layout>
    )
}

export default Details